import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Carousel, Row, Col } from 'antd';
import { Button, Icon } from 'antd';
import useInterval from '../../hooks/useInterval';
import Frame1  from './assets/F1.svg';
import Frame2  from './assets/F2.svg';
import Frame3  from './assets/F3.svg';
import Frame4  from './assets/F4.svg';
import Frame5  from './assets/F5.svg';
import Frame6  from './assets/F6.svg';



const ButtonGroup = Button.Group;
const SvgImages = [
  <Frame1 />,
  <Frame2 />,
  <Frame3 />,
  <Frame4 />,
  <Frame5 />,
  <Frame6 />,
]


const CartoonPlot = (props) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativePath: {regex: "/2019-10-31-attn-nic/cartoon/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
            small: fluid(maxWidth: 800) {
              src
              srcSet
              aspectRatio
              sizes
              tracedSVG
              }          
            }
          }
        }
      }
    }
    `)

  const [currentIndex, setCurrentIndex] = useState(0);
  const [playingAnim, setPlayingAnim] = useState(false);
  const [activeTimer, setActiveTimer] = useState(null);
  const [activeImage, setActiveImage] = useState(SvgImages[currentIndex]);


  function getNextIndex(index) {
      return index >= data.allFile.edges.length-1 ? 0 : index+1;
  }
  
  useInterval(() => {
    const newIndex = getNextIndex(currentIndex);
    setCurrentIndex(newIndex);
  }, playingAnim ? 1000 : null);

  useEffect(() => {
    // setActiveImage(data.allFile.edges[currentIndex].node)
    // console.log(`currentIndex useEffect ${currentIndex}`);
    setActiveImage(SvgImages[currentIndex]);
  }, [currentIndex])

  function showNext() {
    console.log(`show current ${currentIndex} and all is ${data.allFile.edges.length-1}`);
    if (currentIndex < data.allFile.edges.length-1) {
      // const index = currentIndex+1;
      setCurrentIndex(currentIndex+1);  
      // console.log(`setting new index ${currentIndex}`);
    } else {
      setCurrentIndex(0);
    }
  }

  function showPrevious() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex-1);
    } else {
      setCurrentIndex(data.allFile.edges.length-1)
    }
  }

  function animateImages() {
    playingAnim ? setPlayingAnim(false) : setPlayingAnim(true);    
  }

  return (    
    <div >
      <div style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
        {SvgImages[currentIndex]}
      </div>
      <div style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
        <Button type="primary" style={{marginRight: "16px"}} onClick={animateImages}>{playingAnim ? `Pause Animation` : `Play Animation`}</Button>
        <ButtonGroup>
          <Button onClick={showPrevious}  disabled={playingAnim} >
            <Icon type="left" />
            Go back
          </Button>
          <Button onClick={showNext}  disabled={playingAnim} >
            Go forward
            <Icon type="right" />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default CartoonPlot;